import React from 'react';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Header from '../../Component/Header';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FormContainer = styled("form")`
padding : 10px;
`;

const RowContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${(props) => props.theme.spacing(4)};
  max-width: 1000px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(2)};
  justify-items: start;
`;

const Column = styled('div')`
  display: flex;
  flex-direction: column;
  gap : 12px;
`;

const Label = styled('label')`
  margin-bottom: ${(props) => props.theme.spacing(1)};
  font-weight: 700;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin: ${(props) => props.theme.spacing(2, 0)};
`;

const BackButton = styled(Button)`
float : right;
position : sticky;
top : 10px;
`


const UserDetails = () => {
  const { loading, userDetails } = useSelector((state) => state.userDetails)

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Link to="/users"> <BackButton variant='contained'> &larr; BACK </BackButton> </Link>
      <Header title="User Details" subtitle={`USER ID :${userDetails._id}`} />


      <FormContainer onSubmit={handleSubmit}>
        
          <Column>
            <div>
              <Label htmlFor="firstName">First Name</Label>
              <StyledTextField id="firstName" name="firstName" variant="outlined" value={userDetails.firstName} readonly />
            </div>

            <div>
              <Label htmlFor="secondName">Second Name</Label>
              <StyledTextField id="secondName" name="secondName" variant="outlined" value={userDetails.secondName} readonly />
            </div>
            
            <div>
              <Label htmlFor="mail">Email ID</Label>
              <StyledTextField id="mail" name="emailID" variant="outlined" value={userDetails.emailID} readonly />
            </div>

            <div>
              <Label htmlFor="companyName">Company Name</Label>
              <StyledTextField id="companyName" name="companyName"  variant="outlined" value={userDetails.company} readonly />
            </div>
            
          </Column>
      

        {/* <SubmitButton type="submit" variant="contained" color="primary">Edit Details</SubmitButton> */}

      </FormContainer>
    </Box>

  );
};

export default UserDetails;
