import { combineReducers } from 'redux';
import { productDetailsReducer, subCategoriesReducer, subSubCategoriesReducer, userDetailsReducer } from './reducers';



const rootReducer = combineReducers({
    productDetails: productDetailsReducer,
    userDetails: userDetailsReducer,
    subCategories: subCategoriesReducer,
    subSubCategories : subSubCategoriesReducer
});

export default rootReducer