import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import {  Person, Sell, CalendarMonth, CalendarMonthOutlined, ShoppingBag } from "@mui/icons-material";
import { Box, useTheme, useMediaQuery, styled } from "@mui/material";
import StatBox from "../../Component/StatBox";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [userLoading, setUserLoading] = useState(true)
  const [wSaleLoading, setWSaleLoading] = useState(true)
  const [mSaleLoading, setMSaleLoading] = useState(true)
  const [ySaleLoading, setYSaleLoading] = useState(true)
  const [productLoading, setProductLoading] = useState(true)
  const [userList , setUserList] = useState([])
  const [productsData , setProductsData] = useState([])
  const data = {}
  const isLoading = false
  //  const hostName = process.env.REACT_APP_HOST
   const hostName = "https://ecommerce-api.leetwolf.in"

  const FlexBetween = styled(Box)`
  display : flex;
  justify-content : space-between;
  align-content : center;
`;

const allUsersList = async () => {

  var requestOptions = {
    method: 'GET',
    headers: { "Content-Type": "application/json" },
  };

  const response = await fetch(`${hostName}/api/user/userList?userType=user`, requestOptions)
  const data = await response.json()
  setUserList(data);
  setUserLoading(false);
}
const getProducts = async () => {

  try {
    // const { data } = await axios.get(`${hostName}api/Product/getAllProducts?size=${pageSize}&page=${pageNumber}`, data, confg)
    const { data } = await axios.get(`${hostName}/api/Product/getAllProducts`)
    // console.log(data);
    setProductsData(data);
   setProductLoading(false)

  } catch (error) {
    console.log(error)
  }

}

useEffect(() => { getProducts() }, [])

useEffect(() => { allUsersList() }, [])


  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(6, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          fontSize : "50px" ,
          // "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Total Users"
          value={userList.length || "NA" }
          icon={<Person sx={{ color: theme.palette.secondary[300], fontSize: "50px" }} />}
        />
        <StatBox
          title="Products"
          value={productsData.length || "NA" }
          icon={<ShoppingBag sx={{ color: theme.palette.secondary[300], fontSize: "50px" }} />}
        />

      </Box>
      <Box sx={{ display : "flex" , gap:"20px"  , marginTop:"5vh" }}>
      <StatBox
          title="Sales Today"
          // value={data && data.todayStats.totalSales}
          value={12345}
          icon={<Sell sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />}
        />

        <StatBox
          title="Monthly Sales"
          // value={data && data.thisMonthStats.totalSales}
          value={12346}
          icon={<CalendarMonth sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />}
        />
        <StatBox
          title="Yearly Sales"
          // value={data && data.yearlySalesTotal}
          value={12346}
          icon={<CalendarMonthOutlined sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
