import React, { useEffect, useState } from "react";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme, styled, Button, } from "@mui/material";
import {
    SettingsOutlined, ChevronLeft, ChevronRightOutlined, HomeOutlined, ShoppingCartOutlined,
    Groups2Outlined, eceiptLongOutlined, PointOfSaleOutlined, TodayOutlined, CalendarMonthOutlined, AdminPanelSettingsOutlined,
    TrendingUpOutlined, PieChartOutlined, ReceiptLongOutlined, Category, CalendarMonth
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

// import profileImage from "assets/client.png";

const navItems = [
    {
        text: "Dashboard",
        icon: <HomeOutlined />,
    },
    {
        text: "Client Facing",
        icon: null,
    },
    {
        text: "Products",
        icon: <ShoppingCartOutlined />,
    },
    {
        text: "Users",
        icon: <Groups2Outlined />,
    },
    {
        text: "Transactions",
        icon: <ReceiptLongOutlined />,
    },
    {
        text: "Category",
        icon: < Category />,
    },
    {
        text: "Sales",
        icon: null,
    },

    {
        text: "Weekly",
        icon: <CalendarMonth />,
    },
    {
        text: "Monthly",
        icon: <CalendarMonthOutlined />,
    },

];


const FlexBetween = styled(Box)`
display : flex;
justify-content : space-between;
align-content : center;
`;


const Li = styled(ListItem)`
color : white;
&:hover {
background-color: white;
color : #982e53
}
`;

const LiIcon = styled(ListItemIcon)`
color : white;
&:hover {
color : #982e53;
}
`;

const LogOutButtonBox = styled(Box)`
display : flex;
justify-content : center;
margin-top : 10px;
height : "inherit"
`;

const LogOutButton = styled(Button)`
color : #982e53;
background-color : white;
border : solid 1px;
padding : 5px 3rem;
&:hover{
    background-color : #982e53;
    color : white;
}
`;




const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer open={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} variant="persistent" anchor="left"
                    sx={{
                        width: drawerWidth,
                        "& .MuiDrawer-paper": {
                            color: "white",
                            backgroundColor: "#982e53",
                            boxSixing: "border-box",
                            borderWidth: isNonMobile ? 0 : "2px",
                            width: drawerWidth,
                            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
                        },
                    }}
                >
                    <Box width="100%">
                        <Box m="1.5rem 2rem 2rem 3rem">
                            <FlexBetween color={theme.palette.secondary.main}>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Typography variant="h4" sx={{ color: "white" }} fontWeight="bold">ADMIN</Typography>
                                </Box>
                                {!isNonMobile && (
                                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                        <ChevronLeft />
                                    </IconButton>
                                )}
                            </FlexBetween>
                        </Box>

                        {/* Sidebar list */}
                        <List>
                            {navItems.map(({ text, icon }) => {
                                if (!icon) {
                                    return (
                                        <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem", fontWeight: "600", fontSize: "1.3rem" }}>
                                            {text}
                                        </Typography>
                                    );
                                }
                                const lcText = text.toLowerCase();
                                return (
                                    <Li key={text} disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/${lcText}`);
                                                setActive(lcText);
                                            }}
                                            sx={{
                                                backgroundColor:
                                                    active === lcText ? "white" : "transparent",
                                                color:
                                                    active === lcText ? "#982e53" : "",
                                            }}
                                        >
                                            <LiIcon
                                                sx={{
                                                    ml: "2rem",
                                                    color:
                                                        active === lcText
                                                            ? "#982e53"
                                                            : "",
                                                }}
                                            >
                                                {icon}
                                            </LiIcon>
                                            <ListItemText primary={text} />
                                            {active === lcText && <ChevronRightOutlined sx={{ ml: "auto" }} />}
                                        </ListItemButton>
                                    </Li>
                                );
                            })}
                        </List>
                    </Box>
                    <Box mt="2rem">
                        <Divider bottom="4rem" />
                        <LogOutButtonBox>
                            <LogOutButton variant="contained">LogOut</LogOutButton>
                        </LogOutButtonBox>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;
