import React, { useEffect, useState } from 'react'
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Modal, TextField, useTheme } from "@mui/material";
import Header from '../../Component/Header';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSubCategories, getSubSubCategories } from '../../action/action';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "15px"
};


function SubCategory() {
    const theme = useTheme()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const hostName = process.env.REACT_APP_HOST
    const hostName = "https://ecommerce-api.leetwolf.in"
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQwNzU5NzhjODhlMjEwZGU3YTc5Mjc2In0sImlhdCI6MTY3ODIwNzEyOH0.a0cemA528afRT44di-s8x1n_w8ePeV_SCkoLUr-Nx30";
    const [open, setOpen] = useState(false);
    const [isAddMode, setIsAddMode] = useState(true);
    const [editCategoryData, setEditCategoryData] = useState({ name: '' });
    const { loading, data } = useSelector((state) => state.subCategories)
    

    const handleClose = () => setOpen(false);

    const handleOpenForAdd = () => {
        setIsAddMode(true);
        setEditCategoryData({
            name: ''
        });
        setOpen(true);
    };

    const handleOpenForEdit = (category) => {
        setIsAddMode(false);
        setEditCategoryData(category);
        setOpen(true);
    };

    const handleAddCategory = async() => {
      try {
       const parentCategoryID = data.data[0].parentCategory._id
        const config = {
          headers: {
            "auth-token": token
          }
        }
       const subCategorydata = {
        "parentCategoryID": parentCategoryID,
        "subCategoryName": editCategoryData.name
      }

        await axios.post(`${hostName}/api/admin/addSubCategory`, subCategorydata , config)
        dispatch(getSubCategories(parentCategoryID))

      } catch (error) {
        console.log(error)

      }
        setOpen(false);
    };


    const handleEditCategory = () => {
        // Implement the logic to edit the category here
        setOpen(false);
        setEditCategoryData({ name: '' });
    };

    const fetchSubSubCategories = (subCategoryID) => {
        dispatch(getSubSubCategories(subCategoryID))
        navigate("/subSubCategory")
    }

    const handleDelete=(id)=>{

    }

    const columns = [
        {
            field: "name",
            headerName: "Sub Category",
            flex: 1,
        },

        {
            field: "Edit",
            flex: 0.5,
            renderCell: (cellValues) => {
              return (
                <Button variant="outlined" color="primary" onClick={() => handleOpenForEdit(cellValues.row._id)}> Edit </Button>
              );
            }
          },
          {
            field: "Delete",
            flex: 0.5,
            renderCell: (cellValues) => {
              return (
                <Button variant='outlined' color="error" onClick={() => handleDelete(cellValues.row._id) }> Delete </Button>
              );
            }
          },
          {
            field: "SubCategories",
            flex: 0.3,
            renderCell: (cellValues) => {
              return (
                <Button color="secondary" onClick={() => { fetchSubSubCategories(cellValues.row._id) }}>Details &#x2192; </Button>
              );
            }
          },

    ];

    return (
        <Box m="1.5rem 2.5rem" >
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                <Header title="Sub Category" subtitle="Sub Categories List" />
                <Button variant='contained' color='secondary' onClick={handleOpenForAdd} >Add Sub Category</Button>
            </Box>
            <Box
                m="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                      textAlign: "center",
                      padding : "5px"
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "solid 1px",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#982e53;",
                      color: "white",
                      fontWeight : "800",
                      // borderBottom: "solid 1px",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: "white",  // Tabel background color
                    },
                    "& .MuiDataGrid-footerContainer": {
                      // borderTop: "solid 1px",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: "red",
                    },
                  }}
            >
                <DataGrid
                    loading={loading}
                    getRowId={(row) => row._id}
                    // rows={data || []}
                    rows={data.data || []}
                    columns={columns}
                    autoHeight={true}
                    pageSize={8}
                />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3>{isAddMode ? "Add New SubCategory" : "Edit SubCategory"}</h3>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                        <TextField
                            id="category-input"
                            placeholder="Enter category name"
                            variant="outlined"
                            fullWidth
                            value={editCategoryData.name}
                            onChange={(e) => {
                                    setEditCategoryData({
                                        ...editCategoryData,
                                        name: e.target.value,
                                    });
                                
                            }}
                        />
                        <Button variant="outlined" color="primary" onClick={isAddMode ? handleAddCategory : handleEditCategory}>
                            {isAddMode ? "Add" : "Save"}
                        </Button>
                    </Box>
                </Box>
            </Modal>


        </Box>
    )
}

export default SubCategory