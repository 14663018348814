import React, { useEffect, useState } from 'react'
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Modal, TextField, useTheme } from "@mui/material";
import Header from '../../Component/Header';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSubCategories } from '../../action/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "15px"
};


function Category() {
  const theme = useTheme()
  const [loading, setLoading] = useState(true);
  const [mainCategoies, setMainCategories] = useState([])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const hostName = process.env.REACT_APP_HOST
  const hostName = "https://ecommerce-api.leetwolf.in"
  // const token = localStorage.getItem("eToken")
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQwNzU5NzhjODhlMjEwZGU3YTc5Mjc2In0sImlhdCI6MTY3ODIwNzEyOH0.a0cemA528afRT44di-s8x1n_w8ePeV_SCkoLUr-Nx30";


  const [open, setOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [editCategoryData, setEditCategoryData] = useState({});
  const [categoryID, setCategoryID] = useState("");
  const handleClose = () => setOpen(false);
  console.log(editCategoryData);

  const handleOpenForAdd = () => {
    setIsAddMode(true);
    setEditCategoryData({});
    setOpen(true);
  };

  const handleOpenForEdit = (categoryName, id) => {
    setIsAddMode(false);
    setEditCategoryData({ "name": categoryName });
    setCategoryID(id);
    setOpen(true);
  };

  const handleAddCategory = async () => {
    if (editCategoryData.name && editCategoryData.image) {
      try {
        const config = {
          headers: {
            "auth-token": token
          }
        }
        var formdata = new FormData();
        formdata.append("image", editCategoryData.image);
        formdata.append("categoryName", editCategoryData.name);

        await axios.post(`${hostName}/api/admin/addCategory`, formdata, config)
        setOpen(false);
        getMainCategory()

      } catch (error) {
        console.log(error)

      }
    }
    else {
      alert("Both Fields Are Required")
    }

  };


  const handleEditCategory = async() => {
    var myHeaders = new Headers();
    myHeaders.append("auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQwNzU5NzhjODhlMjEwZGU3YTc5Mjc2In0sImlhdCI6MTY3ODIwNzEyOH0.a0cemA528afRT44di-s8x1n_w8ePeV_SCkoLUr-Nx30");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "categoryName": editCategoryData.name
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

   await fetch(`${hostName}/api/admin/updateCategory/${categoryID}`, requestOptions)
   
    setOpen(false);
    setEditCategoryData({ });
    getMainCategory()
  };

  const handleDelete = async(id) => {
    var myHeaders = new Headers();
    // myHeaders.append("auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQwNzU5NzhjODhlMjEwZGU3YTc5Mjc2In0sImlhdCI6MTY3ODIwNzEyOH0.a0cemA528afRT44di-s8x1n_w8ePeV_SCkoLUr-Nx30");

    var requestOptions = {
      method: 'DELETE',
      // headers: myHeaders,
      redirect: 'follow'
    };

   await fetch(`${hostName}/api/admin/removeMainCategory/${id}`, requestOptions)
   getMainCategory()

  }

  
  const fetchSubCategories = (categoryID) => {
   
    dispatch(getSubCategories(categoryID))
    navigate("/subCategory")

  }


  const getMainCategory = async () => {

    try {
      const { data } = await axios.get(`${hostName}/api/admin/getMainCategories`)
      setMainCategories(data.data);
      setLoading(false);

    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => { getMainCategory() }, [])

  const handleImageChange = (e) => {
    setEditCategoryData({
      ...editCategoryData,
      image: e.target.files[0],
    });
  };


  const columns = [
    {
      field: "name",
      headerName: "Category Name",
      flex: 1,
    },

    {
      field: "Edit",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <Button variant="outlined" color="primary" onClick={() => handleOpenForEdit(cellValues.row.name, cellValues.row._id)}> Edit </Button>
        );
      }
    },
    {
      field: "Delete",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <Button variant='outlined' color="error" onClick={() => handleDelete(cellValues.row._id)}> Delete </Button>
        );
      }
    },
    {
      field: "SubCategories",
      flex: 0.3,
      renderCell: (cellValues) => {
        return (
          <Button color="secondary" onClick={() => { fetchSubCategories(cellValues.row._id) }}>Details &#x2192; </Button>
        );
      }
    },

  ];

  return (
    <Box m="1.5rem 2.5rem" >
      <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
        <Header title="Category" subtitle="Main Category List" />
        <Button variant='contained' color='secondary' onClick={handleOpenForAdd} >Add Category</Button>
      </Box>
      <Box
        m="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            textAlign: "center",
            padding: "5px"
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "solid 1px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#982e53;",
            color: "white",
            fontWeight: "800",
            // borderBottom: "solid 1px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",  // Tabel background color
          },
          "& .MuiDataGrid-footerContainer": {
            // borderTop: "solid 1px",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "red",
          },
        }}
      >
        <DataGrid
          loading={loading}
          getRowId={(row) => row._id}
          // rows={data || []}
          rows={mainCategoies || []}
          columns={columns}
          autoHeight={true}
          pageSize={8}
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>{isAddMode ? "Add New Category" : "Edit Category"}</h3>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
            <TextField id="category-input" placeholder="Enter category name" variant="outlined" fullWidth value={editCategoryData.name}
              onChange={(e) => {

                setEditCategoryData({
                  ...editCategoryData,
                  name: e.target.value,
                });

              }}
            />
          </Box>
          {isAddMode ?
            <>
              <h3> Add Category Image</h3>
              <input type="file" accept="image/*" onChange={handleImageChange} />
            </> : null}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
            <Button variant="outlined" color="primary" onClick={isAddMode ? handleAddCategory : handleEditCategory}>
              {isAddMode ? "Add" : "Save"}
            </Button>
          </Box>

        </Box>
      </Modal>


    </Box>
  )
}

export default Category