import React from "react";
import { Box, Typography, useTheme,styled} from "@mui/material";

const StatBox = ({ title, value , icon }) => {
  const theme = useTheme();

  const FlexBetween = styled(Box)`
  display : flex;
  justify-content : space-around;
  align-content : center;
`;

  return (
    <Box
      gridColumn="span 3"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
      textAlign="center"
      border= "solid 1px"
    >
      <FlexBetween>
        <Typography variant="h6" sx={{ color: "#982e53" }}>
          {title}
        </Typography >
        {icon}
      </FlexBetween>

      <Typography variant="h2"  fontWeight="600" sx={{ color: theme.palette.secondary[200] }}>{value}</Typography>
    </Box>
  );
};

export default StatBox;
