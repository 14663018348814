import React from 'react';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Header from '../../Component/Header';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const FormContainer = styled("form")`

`;
const RowContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${(props) => props.theme.spacing(4)};
  max-width: 1000px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(2)};
  justify-items: start;

`;

const Column = styled('div')`
  display: flex;
  flex-direction: column;
  gap : 12px;
`;

const Label = styled('label')`
  margin-bottom: ${(props) => props.theme.spacing(1)};
  font-weight: 700;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin: ${(props) => props.theme.spacing(2, 0)};
`;

const Image = styled("img")`
height: 200px;
 width: 200px;
 border : solid 1px;
`
const BackButton = styled(Button)`
float : right;
position : sticky;
top : 10px;
`

const ProductDetails = () => {
  const { loading, product } = useSelector((state) => state.productDetails)
  const hostName = "https://ecommerce-api.leetwolf.in"

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <Box m="1.5rem 2.5rem">
        <Link to="/products"> <BackButton variant='contained'> &larr; BACK </BackButton> </Link>
      <Header title="Products Details" subtitle={`Product ID : ${product._id}`} />
   

      <FormContainer onSubmit={handleSubmit}>
        <RowContainer >
          <Column>
            <div>
              <Label htmlFor="productName">Product Name</Label>
              <StyledTextField id="productName" name="productName" variant="outlined" value={product.productName} readonly />
            </div>

            {/* <div>
                <Label htmlFor="productID">Product ID</Label>
                <StyledTextField id="productID" name="productID" variant="outlined" value={product._id} readonly />
              </div> */}
            <div>
              <Label htmlFor="productMRP">Product MRP</Label>
              <StyledTextField id="productMRP" name="productMRP" variant="outlined" value={product.productMRP} readonly />
            </div>
            <div>
              <Label htmlFor="productCategory">Product Category</Label>
              <StyledTextField id="productCategory" name="productCategory" variant="outlined" value={product.productCategory} readonly />
            </div>
            <div>
              <Label htmlFor="productQuantity">Product Quantity</Label>
              <StyledTextField id="productQuantity" name="productQuantity" type="number" variant="outlined" value={product.productQuantity} readonly />
            </div>
            <div>
              <Label htmlFor="productBrand">Product Brand</Label>
              <StyledTextField id="productBrand" name="productBrand" variant="outlined" value={product.productBrand} readonly />
            </div>
          </Column>

          <Column>

            <div>
              <div>
                <Label htmlFor="productListingPrice">Product Listing Price</Label>
                <StyledTextField id="productListingPrice" name="productListingPrice" variant="outlined" value={product.productListingPrice} readonly />
              </div>
              <Label htmlFor="productSize">Product Size</Label>
              <StyledTextField id="productSize" name="productSize" variant="outlined" value={product.productSize} readonly />
            </div>
            <div>
              <Label htmlFor="productColor">Product Color</Label>
              <StyledTextField id="productColor" name="productColor" variant="outlined" value={product.productColor} readonly />
            </div>
            <div>
              <Label htmlFor="userID">User ID</Label>
              <StyledTextField id="userID" name="userID" variant="outlined" value={product.userID} readonly />
            </div>
          </Column>

        </RowContainer>
        <Column style={{"alignItems" : "center"}}>
          {product.productImg ? <div style={{ "display": "flex", "gap": "10px" }}>
            <Image src={`${hostName}/${product.productImg[0]}`} alt="Product 1" />
            <Image src={`${hostName}/${product.productImg[1]}`} alt="Product 2" />
            <Image src={`${hostName}/${product.productImg[2]}`} alt="Product 3" />
            <Image src={`${hostName}/${product.productImg[3]}`} alt="Product 4" />
          </div> :
            <div>No Preview Avialable</div>}

          <SubmitButton type="submit" variant="contained" color="primary">Edit Details</SubmitButton>
          </Column>
      </FormContainer>
    </Box>

  );
};

export default ProductDetails;
