import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from './Pages/Layout/Layout';
import Dashboard from './Pages/Dashboard/Dashboard';
import Products from './Pages/Products/Products';
import Users from './Pages/Users/Users';
import Category from './Pages/Category/Category';
import ProductDetails from './Pages/Products/ProductDetails';
import UserDetails from './Pages/Users/UserDetails';
import SubCategory from "./Pages/Category/SubCategory"
import SubCategory2 from './Pages/Category/SubCategory2';
import Transactions from './Pages/Transactions/Transactions';



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="products" element={<Products />} />
            <Route path="users" element={<Users />} />
            <Route path="category" element={<Category />} />
            <Route path="ProductDetails" element={<ProductDetails />} />
            <Route path="UserDetails" element={<UserDetails />} />
            <Route path="subCategory" element={<SubCategory />} />
            <Route path="subSubCategory" element={<SubCategory2 />} />
            <Route path="transactions" element={<Transactions />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
