import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUserDetails } from '../../action/action'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { Box, Button } from '@mui/material'
import Header from '../../Component/Header'
import { DataGrid } from '@mui/x-data-grid'

function Users() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true);
  // const hostName = process.env.REACT_APP_HOST
  const hostName = "https://ecommerce-api.leetwolf.in"
  const theme = useTheme();
 
  const handleDetails = (id) => {
    dispatch(getUserDetails(id))
    navigate("/UserDetails")
  }

  const allUsersList = async () => {
    var requestOptions = {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    };

    const response = await fetch(`${hostName}/api/user/userList?userType=user`, requestOptions)
    const data = await response.json()
    setUserList(data);
    setLoading(false);
  }

  useEffect(() => { allUsersList() }, [])

  const columns = [
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
    },

    {
      field: "emailID",
      headerName: "Email",
      flex: 1,
    },

    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },

    {
      field: "createdAt",
      headerName: "Active From",
      flex: 1,
    },

    {
      field: "Details",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => { handleDetails(cellValues.row._id) }}> Details </Button>
        );
      }
    }
  ];


  return (
    <Box m="1.5rem 2.5rem">
    <Header title="Users" subtitle="List of All Users" />
    <Box
      m="40px"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
          textAlign: "center",
          padding : "5px"
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "solid 1px",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#982e53;",
          color: "white",
          fontWeight : "800",
          // borderBottom: "solid 1px",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "white",  // Tabel background color
        },
        "& .MuiDataGrid-footerContainer": {
          // borderTop: "solid 1px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: "red",
        },
      }}
    >

      <DataGrid
        loading={loading}
        getRowId={(row) => row._id}
        rows={userList || []}
        columns={columns}
        pageSize={8}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: 5 },
        //   },
        // }}
        pageSizeOptions={[5, 10]}
        autoHeight={true}
      />
    </Box>
  </Box>
  )
}

export default Users
