export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_RESET = "USERS_LIST_RESET";
export const USERS_LIST_FAIL = "USERS_LIST_FAIL";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_RESET = "CATEGORY_LIST_RESET";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const GET_SUBCATEGORIES_REQUEST = "GET_SUBCATEGORIES_REQUEST";
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS";
export const GET_SUBCATEGORIES_FAIL = "GET_SUBCATEGORIES_FAIL";

export const GET_SUB_SUBCATEGORIES_REQUEST = "GET_SUB_SUBCATEGORIES_REQUEST";
export const GET_SUB_SUBCATEGORIES_SUCCESS = "GET_SUB_SUBCATEGORIES_SUCCESS";
export const GET_SUB_SUBCATEGORIES_FAIL = "GET_SUB_SUBCATEGORIES_FAIL";