import { USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USERS_LIST_RESET, USERS_LIST_FAIL ,  PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,  GET_SUBCATEGORIES_REQUEST,USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_DETAILS_FAIL,
    GET_SUBCATEGORIES_SUCCESS, GET_SUBCATEGORIES_FAIL, GET_SUB_SUBCATEGORIES_REQUEST, GET_SUB_SUBCATEGORIES_SUCCESS,
    GET_SUB_SUBCATEGORIES_FAIL } from "../Constants/constants";

const initialState = { loading: false, error: null }

// New Product Reducer
export const newProductReducer = (state = { ...initialState, usersData: [] }, { type, payload }) => {
    switch (type) {
        case USERS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USERS_LIST_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                users: payload.users,
            };
        case USERS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case USERS_LIST_RESET:
            return {
                ...state,
                success: false,
            };

        default:
            return state;
    }
}

export const productDetailsReducer = (state = { product: {} }, { type, payload }) => {

    switch (type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                product: {},
                loading: true,
            };
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: payload,
            };
        case PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
export const userDetailsReducer = (state = { userDetails : {} }, { type, payload }) => {

    switch (type) {
        case USER_DETAILS_REQUEST:
            return {
                userDetails : {},
                loading: true,
            };
        case USER_DETAILS_SUCCESS:
            return {
                loading: false,
                userDetails : payload,
            };
        case USER_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}

export const subCategoriesReducer = (state = { data : [] }, { type, payload }) => {

    switch (type) {
        case GET_SUBCATEGORIES_REQUEST:
            return {
                data : [] ,
                loading: true,
            };
        case GET_SUBCATEGORIES_SUCCESS:
            return {
                loading: false,
                data : payload,
            };
        case GET_SUBCATEGORIES_FAIL:
            return {
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}

export const subSubCategoriesReducer = (state = { data : [] }, { type, payload }) => {

    switch (type) {
        case GET_SUB_SUBCATEGORIES_REQUEST:
            return {
                data : [] ,
                loading: true,
            };
        case GET_SUB_SUBCATEGORIES_SUCCESS:
            return {
                loading: false,
                data : payload,
            };
        case GET_SUB_SUBCATEGORIES_FAIL:
            return {
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
