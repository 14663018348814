import axios from "axios";
import {
    USERS_LIST_REQUEST, USERS_LIST_SUCCESS, USERS_LIST_RESET, USERS_LIST_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_DETAILS_FAIL, GET_SUBCATEGORIES_REQUEST,
    GET_SUBCATEGORIES_SUCCESS, GET_SUBCATEGORIES_FAIL, GET_SUB_SUBCATEGORIES_REQUEST, GET_SUB_SUBCATEGORIES_SUCCESS,
    GET_SUB_SUBCATEGORIES_FAIL
} from "../Constants/constants";

// const hostName = process.env.REACT_APP_HOST;
const hostName = "https://ecommerce-api.leetwolf.in";
// const token = localStorage.getItem("eToken")
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQwNzU5NzhjODhlMjEwZGU3YTc5Mjc2In0sImlhdCI6MTY3ODIwNzEyOH0.a0cemA528afRT44di-s8x1n_w8ePeV_SCkoLUr-Nx30";

// All Users 
export const getUsers = () => async (dispatch) => {
    try {
        dispatch({ type: USERS_LIST_REQUEST });
        const confg = {
            headers: { "auth-token": token }
        }
        const { data } = await axios.get(`${hostName}/api/admin/usersList`, confg);
        dispatch({
            type: USERS_LIST_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: USERS_LIST_RESET,
            payload: error.response,
        });
    }
}

// Get Product Details
export const getProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });

        const { data } = await axios.get(`${hostName}/api/Product/productDetails/${id}`);

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get User Details
export const getUserDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: USER_DETAILS_REQUEST });

        const config = {
            headers: { "auth-token": token }
        }
        const { data } = await axios.get(`${hostName}/api/admin/userDetails/${id}`, config);

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error,
        });
    }
};

// Get User Details
export const getSubCategories = (id) => async (dispatch) => {
    try {

        dispatch({ type: GET_SUBCATEGORIES_REQUEST });

        const config = {
            headers: { 'Content-Type': 'application/json' },
        }

        const data = { params: { "parentCategoryID": id } }
        const response = await axios.get(`${hostName}/api/admin/getSubCategories`, data, config)
        const subCategories = response.data;

        dispatch({
            type: GET_SUBCATEGORIES_SUCCESS,
            payload: subCategories,
        });

    } catch (error) {
        dispatch({
            type: GET_SUBCATEGORIES_FAIL,
            payload: error,
        });
    }
};

// Get Sub-sub Categories
export const getSubSubCategories = (id) => async (dispatch) => {
    try {

        dispatch({ type: GET_SUB_SUBCATEGORIES_REQUEST });
        const config = {
            headers: { 'Content-Type': 'application/json' },
        }

        const body = { params: { "parentSubCategoryID": id } } 
        const { data } = await axios.get(`${hostName}/api/admin/getSubSubCategories`, body , config);

        dispatch({
            type: GET_SUB_SUBCATEGORIES_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: GET_SUB_SUBCATEGORIES_FAIL,
            payload: error,
        });
    }
};