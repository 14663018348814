import React, { useEffect, useState } from 'react'
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Modal, TextField, useTheme } from "@mui/material";
import Header from '../../Component/Header';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSubCategories, getSubSubCategories } from '../../action/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "15px"
};


function SubCategory2() {
  const theme = useTheme()
  const dispatch = useDispatch();
  const hostName = process.env.REACT_APP_HOST
  // const hostName = "http://localhost:1000"

  const [open, setOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [editCategoryData, setEditCategoryData] = useState({ name: '' });
  const {loading , data } = useSelector((state)=>state.subSubCategories)
  
  const handleClose = () => setOpen(false);

  const handleOpenForAdd = () => {
    setIsAddMode(true);
    setEditCategoryData({
      name: ''
    });
    setOpen(true);
  };

  const handleOpenForEdit = (category) => {
    setIsAddMode(false);
    setEditCategoryData(category);
    setOpen(true);
  };

  const handleAddCategory = () => {
    // Implement the logic to add a new category here
    setOpen(false);
  };


  const handleEditCategory = () => {
    // Implement the logic to edit the category here
    setOpen(false);
    setEditCategoryData({ name: '' });
  };

  const handleDelete =()=>{
    
  }


  const columns = [
    {
      field: "name",
      headerName: "Sub Category",
      flex: 1,
    },

    {
      field: "Edit",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <Button variant="outlined" color="primary" onClick={() => handleOpenForEdit(cellValues.row._id)}> Edit </Button>
        );
      }
    },
    {
      field: "Delete",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <Button variant='outlined' color="error" onClick={() => handleDelete(cellValues.row._id) }> Delete </Button>
        );
      }
    },

  ];

  return (
    <Box m="1.5rem 2.5rem" >
      <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
        <Header title="Sub SubCategory" subtitle="Sub SubCategories List" />
        <Button variant='contained' color='secondary' onClick={handleOpenForAdd} >Add Sub SubCategory</Button>
      </Box>
      <Box
        m="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            textAlign: "center",
            padding : "5px"
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "solid 1px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#982e53;",
            color: "white",
            fontWeight : "800",
            // borderBottom: "solid 1px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",  // Tabel background color
          },
          "& .MuiDataGrid-footerContainer": {
            // borderTop: "solid 1px",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "red",
          },
        }}
      >
        <DataGrid
          loading={loading}
          getRowId={(row) => row._id}
          // rows={data || []}
          rows={data.data || []}
          columns={columns}
          autoHeight={true}
        />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>{isAddMode ? "Add New SubCategory" : "Edit SubCategory"}</h3>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
            <TextField
              id="category-input"
              placeholder="Enter category name"
              variant="outlined"
              fullWidth
              value={editCategoryData.name}
              onChange={(e) => {
                if (!isAddMode) {
                  // Only update the name if it's not in "Add" mode
                  setEditCategoryData({
                    ...editCategoryData,
                    name: e.target.value,
                  });
                }
              }}
            />
            <Button variant="outlined" color="primary" onClick={isAddMode ? handleAddCategory : handleEditCategory}>
              {isAddMode ? "Add" : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>


    </Box>
  )
}

export default SubCategory2