import React, { useEffect, useState } from 'react'
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";
import Header from '../../Component/Header';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


function Transactions() {
  const [transactionsData, setTransactionsData] = useState([])
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const hostName = process.env.REACT_APP_HOST
  // const hostName = "http://localhost:1000"

  const gettransactions = async () => {

    try {
      // const { data } = await axios.get(`${hostName}api/Product/getAlltransactions?size=${pageSize}&page=${pageNumber}`, data, confg)
      const { data } = await axios.get(`${hostName}/api/Product/getAlltransactions`)
      // console.log(data);
      setTransactionsData(data);
      setLoading(false);

    } catch (error) {
      console.log(error)
    }

  }

//   useEffect(() => { gettransactions() }, [])


  const columns = [
    {
      field: "OrderID",
      headerName: "OrderID",
      flex: 1,
    },
    {
      field: "Amount",
      headerName: "Amount",
      flex: 0.7,
    },
    {
      field: "Result",
      headerName: "Result",
      flex: 0.7,
    },
    // {
    //   field: "phoneNumber",
    //   headerName: "Phone Number",
    //   flex: 1,
    //   rederCell: (parmas) => { return parmas.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");}
    // },
    {
      field: "UserID",
      headerName: "MRP",
      flex: 0.7,
    },
    // {
    //   field: "productListingPrice",
    //   headerName: "Listing Price",
    //   flex: 0.7,
    // },
    {
      field: "createdAt",
      headerName: "Listing Date",
      flex: 1,
    },

    {
      field: "Details",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => { handleDetails(cellValues.row._id) }}> Details </Button>
        );
      }
    }

  ];

  const handleDetails = (id) => {
    // dispatch(getProductDetails(id))
    // navigate("/ProductDetails")
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="transactions" subtitle="List of All transactions" />
      <Box
        m="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            textAlign: "center",
            padding : "5px"
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "solid 1px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#982e53;",
            color: "white",
            fontWeight : "800",
            // borderBottom: "solid 1px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "white",  // Tabel background color
          },
          "& .MuiDataGrid-footerContainer": {
            // borderTop: "solid 1px",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "red",
          },
        }}
      >
        <DataGrid
          loading={loading}
          getRowId={(row) => row._id}
          // rows={data || []}
          rows={transactionsData || []}
          columns={columns}
          autoHeight={true}
          pageSize={8}
        />
      </Box>
    </Box>
  )
}

export default Transactions